import { HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { localStorageSafe } from '../functions';
import { FileService } from '../services/file.service';

const ALLOWED_EXPORT_EXTENSIONS = [
    'csv',
    'xlsx',
];

const ALLOWED_EXPORT_RESPONSE_CONTENT_TYPE = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/csv; charset=UTF-8',
];

@Injectable()
export class DocumentExportInterceptor implements HttpInterceptor {

    public constructor(
        private _fileService: FileService,
    ) { }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const exportedExtension = localStorageSafe.getItem('export_extension');
        if (exportedExtension && !request.url.includes('app/version')) {
            localStorageSafe.removeItem('export_extension');
            request = request.clone({
                params: (request.params ? request.params : new HttpParams())
                    .set('export', exportedExtension),
                responseType: 'arraybuffer'
            });
        }

        return next.handle(request)
            .pipe(
                map((response): any => {
                    if (response instanceof HttpResponse) {
                        ALLOWED_EXPORT_EXTENSIONS.forEach((extension: string) => {
                            if (response.url.includes(`export=${extension}`) && ALLOWED_EXPORT_RESPONSE_CONTENT_TYPE.includes(response.headers.get('content-type'))) {
                                void this._fileService.downloadFileByPlatform(response.body, 'export.' + extension);
                            }
                        });
                    }

                    return response;
                }),
            );
    }
}
