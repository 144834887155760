import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { ID } from '@datorama/akita';
import { TranslateService } from '@ngx-translate/core';
import Mime from 'mime';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Account } from 'src/app/account/models/Account';
import { AccountMove } from 'src/app/account/models/AccountMove';
import { Customer } from 'src/app/crm/models/Customer';
import { Order } from 'src/app/crm/models/Order';
import { Document } from 'src/app/document/models/Document';
import { Employee } from 'src/app/employee/models/Employee';
import { EmployeeActivity } from 'src/app/employee/models/EmployeeActivity';
import { File as FileModel } from 'src/app/employee/models/File';
import { InternalDocument } from 'src/app/internal-document/state/internal-document.model';
import { InventoryItem } from 'src/app/inventory/models/InventoryItem';
import { Project } from 'src/app/project/models/Project';
import { Property } from 'src/app/property/models/Property';
import { AuthenticationService } from '../../core/services/authentication.service';
import { Form } from '../../form/form/state/form.model';
import { DocumentTemplate } from '../../setting/models/DocumentTemplate';
import { Training } from '../../training/state/training.model';
import { ApiHelper } from '../common/ApiHelper';
import { SwalHelper } from '../common/SwalHelper';
import { ApiResponse } from '../models/ApiResponse';

interface Downloadable {
    extension: string;
    fileBase64: string;
    name: string;
}

@Injectable({
    providedIn: 'root'
})
export class FileService {
    public constructor(
        private _apiHelper: ApiHelper,
        private _http: HttpClient,
        private _loaderService: NgxUiLoaderService,
        private _swalHelper: SwalHelper,
        private _authService: AuthenticationService,
        private _translateService: TranslateService
    ) { }

    public base64ToArrayBuffer(base64: string): ArrayBuffer {
        const binaryString = window.atob(base64);

        const len = binaryString.length;

        const bytes = new Uint8Array(len);

        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        return bytes.buffer;
    }

    public base64toBlob(b64Data: string, contentType: string): Blob {
        contentType = contentType || '';

        const sliceSize = 512;

        b64Data = b64Data.replace(/^[^,]+,/, '');
        b64Data = b64Data.replace(/\s/g, '');

        const byteCharacters = window.atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);

            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, {type: contentType});

        return blob;
    }

    public checkMimeTypeAndDownloadWithAnchor(file: Downloadable): void {
        /* === NEW SOLUTION */
        console.log(file);
        let mimeType = this.getMIMEType(file.extension);
        // TODO: check if mimeType is undefined set octet-stream
        if (
            mimeType === 'undefined' ||
            mimeType === '' ||
            mimeType === null
        ) {
            mimeType = 'application/octet-stream';
        }

        let fileName = file.name;

        if (
            file.extension &&
            !fileName.endsWith(file.extension)
        ) {
            fileName += `.${file.extension}`;
        }

        void this.downloadFileByPlatform(this.base64ToArrayBuffer(file.fileBase64), fileName);

        this._loaderService.stop();
    }

    public async downloadFileByPlatform(arrayBuffer: ArrayBuffer, fileName: string): Promise<void> {
        if (Capacitor.isNativePlatform()) {
            const fileBase64 = this.arrayBufferToBase64(arrayBuffer);
            let permissionGranted = false;

            // ios doesn't need permission
            if (Capacitor.getPlatform() === 'ios') {
                permissionGranted = true;
            }

            // android needs permission check
            if (Capacitor.getPlatform() === 'android') {
                permissionGranted = (await Filesystem.requestPermissions()).publicStorage === 'granted';
            }

            if (permissionGranted) {
                if (Capacitor.isPluginAvailable('Filesystem')) {
                    await Filesystem.writeFile({
                        path: fileName,
                        data: fileBase64,
                        directory: Capacitor.getPlatform() === 'ios' ? Directory.External : Directory.Documents,
                    });

                    this._apiHelper.showSuccessMessage(this._translateService.instant('files.download_successful'));
                }
            }
        } else {
            const blob = new Blob([arrayBuffer], {type: 'application/binary'});
            const a = document.createElement('a');
            a.href = URL.createObjectURL(blob);
            a.download = fileName;
            a.click();
            a.remove();
        }
    }

    public arrayBufferToBase64(buffer: ArrayBuffer): string {
        const binaryChunks = [];
        const bytes = new Uint8Array(buffer);
        const chunkSize = 8192; // Bezpečná velikost chunku

        for (let i = 0; i < bytes.length; i += chunkSize) {
            binaryChunks.push(String.fromCharCode(...bytes.slice(i, i + chunkSize)));
        }

        return btoa(binaryChunks.join(''));
    }

    public dataURLtoFile(dataUrl: string, filename: string): File {
        const arr = dataUrl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, {type: mime});
    }

    public deleteFileByID(
        fileID: number,
        object: Employee | Form | EmployeeActivity | Account | AccountMove | Customer | Document | Order | Property | InternalDocument | Training | Project | InventoryItem | DocumentTemplate,
        objectType: string
    ): Promise<'done'> {
        let params = null;

        if (objectType === 'EMPLOYEE') {
            const tmpObj = object as Employee;

            params = new HttpParams().set('employee_ID', tmpObj.employee_ID.toString());
        } else if (objectType === 'EMPLOYEE_ACTIVITY') {
            const tmpObj = object as EmployeeActivity;

            params = new HttpParams().set('employee_activity_ID', tmpObj.employee_activity_ID.toString());
        } else if (objectType === 'ACCOUNT') {
            const tmpObj = object as Account;

            params = new HttpParams().set('account_ID', tmpObj.account_ID.toString());
        } else if (objectType === 'ACCOUNTMOVE') {
            const tmpObj = object as AccountMove;

            params = new HttpParams().set('account_move_ID', tmpObj.account_move_ID.toString());
        } else if (objectType === 'CUSTOMER') {
            const tmpObj = object as Customer;

            params = new HttpParams().set('customer_ID', tmpObj.customer_ID.toString());
        } else if (objectType === 'DOCUMENT') {
            const tmpObj = object as Document;

            params = new HttpParams().set('document_ID', tmpObj.document_ID.toString());
        } else if (objectType === 'DOCUMENT_TEMPLATE') {
            const tmpObj = object as DocumentTemplate;

            params = new HttpParams().set('document_template_ID', tmpObj.document_template_ID.toString());
        } else if (objectType === 'FORM') {
            const tmpObj = object as Form;

            params = new HttpParams().set('form_ID', tmpObj.form_ID.toString());
        } else if (objectType === 'ORDER') {
            const tmpObj = object as Order;

            params = new HttpParams().set('order_ID', tmpObj.order_ID.toString());
        } else if (objectType === 'PROPERTY') {
            const tmpObj = object as Property;

            params = new HttpParams().set('property_ID', tmpObj.property_ID.toString());
        } else if (objectType === 'INTERNALDOCUMENT') {
            const tmpObj = object as InternalDocument;

            params = new HttpParams().set('internal_document_ID', tmpObj.internal_document_ID.toString());
        } else if (objectType === 'TRAINING') {
            const tmpObj = object as Training;

            params = new HttpParams().set('training_ID', tmpObj.training_ID.toString());
        } else if (objectType === 'PROPERTY_FILE') {
            const tmpObj = object as Employee;

            params = new HttpParams().set('employee_property_ID', tmpObj.employee_property_ID.toString());
        } else if (objectType === 'PROJECT') {
            const tmpObj = object as Project;

            params = new HttpParams().set('project_ID', tmpObj.project_ID.toString());
        } else if (objectType === 'INVENTORYITEM') {
            const tmpObj = object as InventoryItem;

            params = new HttpParams().set('inventory_item_ID', tmpObj.inventory_item_ID.toString());
        } else if (objectType === 'LOGO') {
            params = new HttpParams().set('file_type', 'settings_company_info_logo_file_ID');
        } else if (objectType === 'DOCUMENTLOGO') {
            params = new HttpParams().set('file_type', 'settings_company_info_documents_logo_file_ID');
        } else if (objectType === 'SIGNATURE') {
            params = new HttpParams().set('file_type', 'settings_company_info_signature_file_ID');
        }

        return new Promise(async (resolve, reject) => {
            const deleteConfirmed = await this._swalHelper.showConfirmDeleteDialog();

            if (deleteConfirmed) {
                this._http.delete<ApiResponse>('/api/files/' + fileID, {params})
                    .subscribe(
                        response => {
                            this._loaderService.stop();

                            this._apiHelper.handleSuccessResponse(response);

                            resolve('done');
                        },
                        error => {
                            this._loaderService.stop();

                            this._apiHelper.handleErrorResponse(error);
                        }
                    );
            } else {
                reject('error');
            }
        });
    }

    public downloadFile(hash: string): void {
        this.getFileByHash(hash)
            .subscribe(
                file => {
                    this.checkMimeTypeAndDownloadWithAnchor(file);
                },
                error => {
                    this._loaderService.stop();

                    this._apiHelper.handleErrorResponse(error);
                }
            );
    }

    public getMIMEType(extension: string): any {
        return Mime.getType(extension);
    }

    public getFileByHash(pdfHash: string, externalURL = false): Observable<FileModel> {
        let params = null;
        if (externalURL) {
            params = new HttpParams().set('external_link', externalURL);
        }
        return this._http.get<FileModel>('/api/files/' + pdfHash, {params});
    }

    public removeFileSignature(fileID: number): Promise<any> {
        return this._http.post(`/api/files/${fileID}/remove-signature`, {}).toPromise();
    }

    public signFile(
        fileID: number,
        markSigned: boolean,
        signatureBase64?: string
    ) {
        return this._http.post<ApiResponse>(`/api/files/${fileID}/sign`, {mark_signed: markSigned, signature_base64: signatureBase64})
            .pipe(
                map(response => this._apiHelper.handleSuccessResponse(response)),
                catchError(error => {
                    this._apiHelper.handleErrorResponse(error);

                    return error;
                }))
            .toPromise();
    }

    public signDocument(
        documentID: number,
        signature: string,
        saveSignature: boolean,
        useSavedSignature: boolean,
        location: string
    ) {
        return this._http.post<ApiResponse>(`/api/documents/${documentID}/sign`, {signature, saveSignature, useSavedSignature, location})
            .pipe(
                map(response => this._apiHelper.handleSuccessResponse(response)),
                catchError(error => {
                    this._apiHelper.handleErrorResponse(error);

                    return error;
                }))
            .toPromise();
    }

    public updateFileByID(form: FileModel, fileID: number | string): Promise<'done'> {
        this._loaderService.start();

        return new Promise(resolve => {
            this._http.post<ApiResponse>('/api/files/' + fileID, form)
                .subscribe(
                    response => {
                        this._apiHelper.handleSuccessResponse(response);

                        this._loaderService.stop();

                        resolve('done');
                    },
                    error => {
                        this._loaderService.stop();

                        this._apiHelper.handleErrorResponse(error);
                    }
                );
        });
    }

    public uploadFile(
        file: File,
        objectTypeKey: string,
        objectTypeID: number | string,
        fileType?: string,
        additionalData?: {
            [key: string]: any
        },
    ): Promise<'done'> {
        const formData = new FormData();

        formData.append(objectTypeKey, objectTypeID.toString());
        formData.append('file', file);

        if (fileType) {
            formData.append('file_type', fileType);
        }

        if (additionalData) {
            for (const key in additionalData) {
                if (!additionalData.hasOwnProperty(key)) {
                    continue;
                }

                formData.append(key, String(additionalData[key]));
            }
        }

        return new Promise(resolve => {
            this._http.post<ApiResponse>('/api/files', formData)
                .subscribe(
                    response => {
                        this._loaderService.stop();

                        this._apiHelper.handleSuccessResponse(response);

                        resolve('done');
                    },
                    error => {
                        this._loaderService.stop();

                        this._apiHelper.handleErrorResponse(error);
                    }
                );
        });
    }

    public requireSignature(employeeFileID: ID): Promise<'done'> {
        this._loaderService.start();

        return new Promise(resolve => {
            this._http.get<ApiResponse>(`/api/employees-files/${employeeFileID}/sign-required`)
                .subscribe(
                    response => {
                        this._apiHelper.handleSuccessResponse(response);

                        this._loaderService.stop();

                        resolve('done');
                    },
                    error => {
                        this._loaderService.stop();

                        this._apiHelper.handleErrorResponse(error);
                    }
                );
        });
    }

    public disregardSignature(employeeFileID: ID): Promise<'done'> {
        this._loaderService.start();

        return new Promise(resolve => {
            this._http.get<ApiResponse>(`/api/employees-files/${employeeFileID}/sign-disregarded`)
                .subscribe(
                    response => {
                        this._apiHelper.handleSuccessResponse(response);

                        this._loaderService.stop();

                        resolve('done');
                    },
                    error => {
                        this._loaderService.stop();

                        this._apiHelper.handleErrorResponse(error);
                    }
                );
        });
    }

    public sendNotification(employeeFileID: ID): Promise<'done'> {
        this._loaderService.start();

        return new Promise(resolve => {
            this._http.get<ApiResponse>(`/api/employees-files/${employeeFileID}/remind-signature`)
                .subscribe(
                    response => {
                        this._apiHelper.handleSuccessResponse(response);

                        this._loaderService.stop();

                        resolve('done');
                    },
                    error => {
                        this._loaderService.stop();

                        this._apiHelper.handleErrorResponse(error);
                    }
                );
        });
    }

    public signEmployeeFile(employeeFileID: number, form?: { signature?: string, save_signature?: boolean, use_saved_signature?: boolean, location?: string, signature_type: string }): Promise<ApiResponse & { next_hash: string }> {
        return new Promise(resolve => {
            this._http.post<ApiResponse & { next_hash: string }>(`/api/employees-files/${employeeFileID}/sign`,
                {signature_base64: form?.signature, location: form?.location, save_signature: form?.save_signature, signature_type: form?.signature_type})
                .subscribe(
                    response => {
                        this._apiHelper.handleSuccessResponse(response);

                        this._loaderService.stop();

                        resolve(response);
                    },
                    error => {
                        this._loaderService.stop();

                        this._apiHelper.handleErrorResponse(error);
                    }
                );
        });
    }

    public dataURItoFile(dataURI: string, fileName: string): File {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        const blob = new Blob([ab], {type: mimeString});
        return new File([blob], fileName, {type: mimeString});
    }
}
