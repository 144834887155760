<div class="modal-content">
    <div class="modal-header">
        <p class="modal-title font-16 mb-0 text-dark">{{ 'document_templates.generate_modal_title' | translate }}</p>

        <button aria-label="Close" class="close" (click)="activeModal.dismiss()">
      <span aria-hidden="true" class="font-24 text-muted">
        <i class="mdi mdi-close"></i>
      </span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="card mb-0 shadow-none">
                    <div class="card-body p-0">
                        <form autocomplete="off" #fGenerate="ngForm" (ngSubmit)="onSubmit(fGenerate)">
                            <div class="row">
                                <div class="col">
                                    <div class="d-flex mb-2">
                                        <div class="form-group flex-grow-1 w-100">
                                            <label class="control-label">{{ 'document_templates.select_template' | translate }}</label>
                                            <ng-select (change)="onTemplateChange(fGenerate)" [(ngModel)]="selectedTemplate" name="documentTemplateType" [clearable]="false" class="custom" required>
                                                <ng-option *ngFor="let documentTemplate of documentTemplates" [value]="documentTemplate" [innerHTML]="documentTemplate.name + (documentTemplate.description ? ' (' + documentTemplate.description +')' : '')"></ng-option>
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="selectedTemplate?.signable && (availableFeatures$ | async)?.signatures" class="row">
                                <div class="col">
                                    <div class="d-flex mb-2">
                                        <div class="alert alert-warning mb-2 w-100 shadow">
                                            <span> {{ 'document_templates.signable' | translate }} </span>
                                            <span *ngIf="selectedTemplate.require_owner_signature">{{ 'document_templates.by_owner' | translate }}</span>
                                            <span *ngIf="selectedTemplate.require_owner_signature && selectedTemplate.require_responsible_signature"> {{ 'global.and' | translate }} </span>
                                            <span *ngIf="selectedTemplate.require_responsible_signature">{{ 'document_templates.by_responsible' | translate }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="selectedTemplate?.signable && (availableFeatures$ | async)?.signatures && selectedTemplate.external_signature?.service === 'SIGNI'" class="row">
                                <div class="col">
                                    <div class="d-flex mb-2">
                                        <div class="form-group">
                                            <label class="control-label">{{ 'documents.signi_postpone' | translate }}</label>
                                            <div>
                                                <input [(ngModel)]="postponeSignature" data-switch="success" id="postpone-signature" name="postpone-signature" type="checkbox">
                                                <label for="postpone-signature">&nbsp;</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="selectedTemplate?.show_position_select">
                                <div class="row mb-2">
                                    <div class="col">
                                        <label class="control-label required">{{ 'employees.filter_section_employee_position' | translate }}</label>
                                        <ng-select
                                            class="custom"
                                            [(ngModel)]="selectedPosition"
                                            id="selectedPosition"
                                            name="selectedPosition"
                                            [clearable]="true"
                                            [items]="employeePositions$ | async"
                                            (change)="onPositionChange(fGenerate)"
                                        >
                                            <ng-template ng-label-tmp let-item="item">
                                                <span>
                                                    {{ item.name }}
                                                    ({{ item.from }}<span *ngIf="item.to"> - {{ item.to }}</span>) -
                                                    <span *ngIf="item.company_branch_name"> {{ item.company_branch_name }}</span>
                                                    <span *ngIf="!item.company_branch_name"> {{ 'company_branches.no_branch' | translate }}</span>
                                                </span>
                                            </ng-template>
                                            <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
                                                {{ item.name }}
                                                ({{ item.from }}<span *ngIf="item.to"> - {{ item.to }}</span>) -
                                                <span *ngIf="item.company_branch_name"> {{ item.company_branch_name }}</span>
                                                <span *ngIf="!item.company_branch_name"> {{ 'company_branches.no_branch' | translate }}</span>
                                            </ng-template>

                                        </ng-select>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="selectedTemplate?.show_salary_select">
                                <div class="row mb-2">
                                    <div class="col">
                                        <label class="control-label required">{{ 'employees.filter_section_employee_salary' | translate }}</label>
                                        <ng-select
                                            class="custom"
                                            [(ngModel)]="selectedSalary"
                                            id="selectedSalary"
                                            name="selectedSalary"
                                            [clearable]="true"
                                            [items]="employeeSalaries$ | async"
                                            (change)="onSalaryChange(fGenerate)"
                                        >
                                            <ng-template ng-label-tmp let-item="item">
                                                <span>{{ item.amount | localizedCurrency}} ({{ item.from }}<span *ngIf="item.to"> - {{ item.to }}</span>)</span>
                                            </ng-template>
                                            <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
                                                <span>{{ item.amount }} ({{ item.from }}<span *ngIf="item.to"> - {{ item.to }}</span>)</span>
                                            </ng-template>

                                        </ng-select>
                                    </div>
                                </div>
                            </div>

                            <ng-container *ngIf="selectedTemplate">
                                <ng-container *ngFor="let group of selectedTemplate?.fields">
                                    <div class="row">
                                        <div class="col">
                                            <h5>{{ ('document_templates.group_' + group.group) | translate }}</h5>
                                            <hr>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="group.group === 'POSITION' && selectedTemplate?.show_position_select && !selectedPosition">
                                        <div class="row">
                                            <div class="col">
                                                <div class="alert alert-danger mb-2 w-100 shadow">
                                                    <span>{{ 'document_templates.had_to_select' | translate }}
                                                        <u (click)="scrollToAnchor('selectedPosition')">{{ 'document_templates.select_position' | translate }}</u>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="group.group === 'SALARY' && selectedTemplate?.show_salary_select && !selectedSalary">
                                        <div class="row">
                                            <div class="col">
                                                <div class="alert alert-danger mb-2 w-100 shadow">
                                                    <span>{{ 'document_templates.had_to_select' | translate }}
                                                        <u (click)="scrollToAnchor('selectedSalary')">{{ 'document_templates.select_salary' | translate }}</u>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngFor="let field of group?.data">
                                        <div class="row">
                                            <div class="col">
                                                <div class="form-group">
                                                    <ng-container *ngIf="field.type === 'custom'; else autoField">
                                                        <label class="control-label" [class.required]="field.required">{{field.name}}</label>
                                                        <input ngModel="{{ field.value }}" type="text" class="form-control" name="{{ field.name }}" value="{{ field.value }}" [required]="field.required"/>
                                                    </ng-container>
                                                    <ng-template #autoField>
                                                        <label *ngIf="!field.label" class="control-label" [class.required]="field.required">{{ ('document_templates.field_' + field.name + '_title') | translate }}</label>
                                                        <label *ngIf="field.label" class="control-label" [class.required]="field.required">{{ field.label }}</label>
                                                        <input ngModel="{{ field.value }}" type="text" class="form-control" name="{{ field.name }}" value="{{ field.value }}" [required]="field.required"/>
                                                        <small *ngIf="!fGenerate.controls[field.name]?.value && field.required && field.disabled" class="text-danger mr-1">{{ ('document_templates.field_' + field.name + '_error_note') | translate }}</small>
                                                        <small *ngIf="!['', 'document_templates.field_' + field.name + '_note'].includes((('document_templates.field_' + field.name + '_note') | translate))" class="text-muted">{{ ('document_templates.field_' + field.name + '_note') | translate }}</small>
                                                    </ng-template>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>

                                <div class="row">
                                    <div class="col text-right">
                                        <button [disabled]="fGenerate.form.invalid || loading" class="btn btn-success" type="submit">
                                            <span *ngIf="!loading">{{ 'document_templates.action_generate' | translate }}</span>
                                            <div *ngIf="loading" class="spinner-border spinner-border-sm" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </ng-container>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
