<div class="modal-content">
  <div class="modal-header">
    <p *ngIf="!documentTemplate" class="modal-title font-16 mb-0 text-dark">{{'settings.company.documents_templates.create_modal_title' | translate}}</p>
    <p *ngIf="documentTemplate" class="modal-title font-16 mb-0 text-dark">{{'settings.company.documents_templates.edit_modal_title' | translate}}</p>
    <button (click)="activeModal.dismiss()" aria-label="Close" class="close" type="button">
      <span aria-hidden="true" class="font-24 text-muted"><i class="mdi mdi-close"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-0 shadow-none">
          <div class="card-body p-0">
            <form (ngSubmit)="onSubmit()" [formGroup]="documentTemplateForm" autocomplete="off">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label class="control-label">{{'settings.company.documents_templates.name' | translate}}</label>
                    <input class="form-control"
                           formControlName="name"
                           name="name"
                           (keyup)="onInputChanged($event)"
                           type="text">
                    <div *ngIf="submitted && documentTemplateForm.controls.name.errors">
                      <p *ngIf="documentTemplateForm.controls.name.errors.required" class="text-danger validation-text">
                        {{ 'angular_validation.field' | translate }} {{ 'settings.company.documents_templates.name' | translate}}
                        {{ 'angular_validation.required' | translate }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label class="control-label">{{'settings.company.documents_templates.code' | translate}}</label>
                    <input class="form-control"
                           formControlName="code"
                           name="code"
                           [readonly]="!!documentTemplate"
                           type="text">
                      <div class="text-right mt-1">
                          <small class="text-muted">
                              <span> {{'settings.company.documents_templates.code_description' | translate}} </span>
                              <span class="text-nowrap"> {{ documentTemplateForm.controls.code.value + '-0001' }} </span>
                          </small>
                      </div>
                    <div *ngIf="submitted && documentTemplateForm.controls.code.errors">
                      <p *ngIf="documentTemplateForm.controls.code.errors.required" class="text-danger validation-text">
                        {{ 'angular_validation.field' | translate }} {{ 'settings.company.documents_templates.code' | translate}}
                        {{ 'angular_validation.required' | translate }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="documentTemplate" class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label class="control-label">{{'settings.company.documents_templates.description' | translate}}</label>
                    <input class="form-control"
                           formControlName="description"
                           name="description"
                           type="text">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6">
                  <label class="control-label">{{ 'settings.company.documents_templates.active' | translate }}</label>
                </div>
                <div class="col-6 text-right">
                  <input data-switch="success"
                         formControlName="active"
                         id="active"
                         name="active"
                         type="checkbox">
                  <label for="active"> </label>
                </div>
              </div>

                <ng-container *ngIf="documentTemplate">
                    <div *ngIf="documentTemplate.require_responsible_signature && collapseExternalSignature" class="row">
                        <div class="col-6">
                            <label class="control-label">{{ 'settings.company.documents_templates.responsible_autosign' | translate }}</label>
                        </div>
                        <div class="col-6 text-right">
                            <input data-switch="success"
                                   formControlName="responsible_autosign"
                                   id="responsible_autosign"
                                   name="responsible_autosign"
                                   type="checkbox">
                            <label for="responsible_autosign"> </label>
                        </div>
                        <div *ngIf="!documentTemplate.responsible_autosign_available" class="col-12 texg-right">
                            <small class="text-muted">
                                {{ 'settings.company.documents_templates.responsible_autosign_description' | translate }}
                            </small>
                        </div>
                    </div>

                    <hr>

                    <h4 class="my-3"> {{ 'settings.company.documents_templates.notifications_hr' | translate }} </h4>

                    <ng-container [formGroupName]="'hr_notification'">
                        <div class="row">
                            <div class="col-9">
                                <label class="control-label">{{ 'settings.company.documents_templates.send_notifications_hr' | translate }}</label>
                            </div>
                            <div class="col-3 text-right align-self-center">
                                <div>
                                    <input data-switch="success" formControlName="send" id="send_hr" name="send" type="checkbox">
                                    <label for="send_hr">&nbsp;</label>
                                </div>
                            </div>
                        </div>
                        <div [(ngbCollapse)]="!documentTemplateForm.value.hr_notification.send">
                            <div class="row mb-2">
                                <div class="col-12">
                                    <div class="form-group mb-1">
                                        <label class="control-label">
                                            <span>{{ 'settings.company.documents_templates.frequency' | translate }}</span>
                                        </label>
                                        <input class="form-control"
                                               type="number"
                                               onlyNumber
                                               formControlName="frequency"
                                               name="frequency">
                                    </div>
                                </div>
                                <div class="col-12 text-muted text-right">
                                    <small>
                                        {{ 'settings.company.documents_templates.notifications_scheduled_by' | translate }}
                                        {{ documentTemplateForm.value.hr_notification.frequency }}
                                        {{ 'settings.company.documents_templates.notifications_scheduled_by_days' | translate }}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <hr>

                    <h4 class="my-3"> {{ 'settings.company.documents_templates.notifications_employee' | translate }} </h4>

                    <ng-container [formGroupName]="'employee_notification'">
                        <div class="row">
                            <div class="col-9">
                                <label class="control-label">{{ 'settings.company.documents_templates.send_notifications_employee' | translate }}</label>
                            </div>
                            <div class="col-3 text-right align-self-center">
                                <div>
                                    <input data-switch="success" formControlName="send" id="send_employee" name="send" type="checkbox">
                                    <label for="send_employee">&nbsp;</label>
                                </div>
                            </div>
                        </div>
                        <div [(ngbCollapse)]="!documentTemplateForm.value.employee_notification.send">
                            <div class="row mb-2">
                                <div class="col-12">
                                    <div class="form-group mb-1">
                                        <label class="control-label">
                                            <span>{{ 'settings.company.documents_templates.frequency' | translate }}</span>
                                        </label>
                                        <input class="form-control"
                                               type="number"
                                               onlyNumber
                                               formControlName="frequency"
                                               name="frequency">
                                    </div>
                                </div>
                                <div class="col-12 text-muted text-right">
                                    <small>
                                        {{ 'settings.company.documents_templates.notifications_scheduled_by' | translate }}
                                        {{ documentTemplateForm.value.employee_notification.frequency }}
                                        {{ 'settings.company.documents_templates.notifications_scheduled_by_days' | translate }}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="canShowExternalSignature">
                    <hr>

                    <div class="row">
                        <div class="col-9">
                            <label class="control-label">{{ 'settings.company.documents_templates.signi_settings' | translate }}</label>
                        </div>
                        <div class="col-3 text-right align-self-center">
                            <div>
                                <input data-switch="success"
                                       id="collapse_external"
                                       name="collapse_external"
                                       type="checkbox"
                                       [checked]="!collapseExternalSignature"
                                       (change)="onCollapseExternalSignature()">
                                <label for="collapse_external">&nbsp;</label>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="!collapseExternalSignature"
                         [formGroupName]="'external_signature'">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="control-label">{{ 'settings.company.documents_templates.service' | translate }}</label>
                                    <ng-select class="custom"
                                               formControlName="service"
                                               [items]="signatureServices"
                                               [multiple]="false"
                                               [closeOnSelect]="true"
                                               name="service">
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 text-right">
                                <small class="text-muted">
                                    <span>{{ 'settings.company.documents_templates.external_signature_description' | translate }} </span>
                                    <a [queryParams]="{open: 'signi'}" routerLink="/setting/integration">{{ 'settings.company.documents_templates.external_signature_description_link' | translate }}</a>
                                </small>
                            </div>
                        </div>
                    </div>
                </ng-container>

              <div class="row mt-2">
                <div class="col d-flex justify-content-end">
                    <button (click)="deleteDocumentTemplate()" *ngIf="documentTemplate?.generated_count === 0" class="btn btn-link text-muted pr-0 mr-4" type="button">
                        <u>{{ 'document_templates.action_delete_document_template' | translate }}</u>
                    </button>
                  <button class="btn btn-success" type="submit">
                    {{'settings.company.documents_templates.modal_action_save' | translate}}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
