import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { SubscriptionService } from 'src/app/setting/services/subscription.service';
import { DocumentTemplate } from '../../../../setting/models/DocumentTemplate';
import { AvailableFeatures } from '../../../models/AvailableFeatures';
import { EmployeePosition } from '../../../models/EmployeePosition';
import { Salary } from '../../../models/Salary';
import { DocumentService } from '../../../services/document.service';
import { EmployeePositionService } from '../../../services/employee-position.service';
import { SalaryService } from '../../../services/salary.service';

@Component({
    selector: 'app-create-document-by-template-modal',
    templateUrl: './create-document-by-template-modal.component.html',
    styleUrls: ['./create-document-by-template-modal.component.css']
})
export class CreateDocumentByTemplateModalComponent implements OnInit {

    public availableFeatures$: Observable<AvailableFeatures>;
    public documentTemplates: Array<DocumentTemplate>;
    @Input() public employeeId: number;
    @Input() public entityType: string = null;
    @Input() public entityId: number = null;

    public selectedTemplate: DocumentTemplate;
    public employeePositions$: Observable<Array<EmployeePosition>>;
    public selectedPosition: EmployeePosition;
    public employeeSalaries$: Observable<Array<Salary>>;
    public selectedSalary: Salary;
    public submitted = false;
    public loading = false;
    public postponeSignature = true;

    constructor(
        public activeModal: NgbActiveModal,
        private _documentService: DocumentService,
        private _positionService: EmployeePositionService,
        private _salaryService: SalaryService,
        private _subscriptionService: SubscriptionService,
        private el: ElementRef,
    ) {}

    public ngOnInit(): void {
        this.availableFeatures$ = this._subscriptionService.availableFeatures$;
        this.employeePositions$ = this._positionService.getAllPositionsByEmployeeID(this.employeeId, true);
        this.employeeSalaries$ = this._salaryService.getAllSalariesByEmployeeID(this.employeeId, true);
        this._documentService.getAvailableDocumentTemplates(this.employeeId, this.entityType, this.entityId).subscribe((data) => {
            // namapovani vychozich hodnot z default_values do jednotlivych policek
            data.forEach((documentTemplate, templateIndex) => {
                if (documentTemplate.default_values) {
                    documentTemplate.fields.forEach((group, groupIndex) => {
                        group.data.forEach((field, fieldIndex) => {
                            if (field.name in documentTemplate.default_values) {
                                data[templateIndex].fields[groupIndex].data[fieldIndex].value = documentTemplate.default_values[field.name];
                            }
                        });
                    });
                }
            });
            this.documentTemplates = data;
        });
    }

    public onSubmit(form: NgForm): void {
        this.submitted = true;
        if (form.valid) {
            this.loading = true;
            const data = {
                ...form.value,
                entity_type: this.entityType,
                entity_id: this.entityId,
                postpone_signature: this.selectedTemplate?.external_signature?.service === 'SIGNI' ? this.postponeSignature : undefined,
            };
            delete data.selectedPosition;
            delete data.selectedSalary;
            this._documentService.saveDocumentFromTemplate(this.employeeId, data)
                .then(() => {
                    this.activeModal.close('close');
                    this.loading = false;
                });
        }
    }

    public onPositionChange(form: NgForm): void {
        const templateData = this.selectedPosition?.document_template;
        if (!templateData) {
            Object.keys(form.controls).forEach(key => {
                if (key.startsWith('JOB_')) {
                    form.controls[key].setValue('');
                }
            });
            return;
        }
        templateData.forEach((field) => {
            if (form.controls[field.name] !== undefined) {
                form.controls[field.name].setValue(field.value);
            }
        });
    }

    public onSalaryChange(form: NgForm): void {
        const templateData = this.selectedSalary?.document_template;
        if (!templateData) {
            Object.keys(form.controls).forEach(key => {
                if (key === 'GROSS_WAGE' || key === 'VARIABLE_WAGE_COMPONENT' || key.startsWith('SALARY_')) {
                    form.controls[key].setValue('');
                }
            });
            return;
        }
        templateData.forEach((field) => {
            if (form.controls[field.name] !== undefined) {
                form.controls[field.name].setValue(field.value);
            }
        });
    }

    scrollToAnchor(anchor: string): void {
        const element = this.el.nativeElement.querySelector(`#${anchor}`);

        if (element) {
            const parentContainer = element.parentElement;

            if (parentContainer) {
                parentContainer.scrollIntoView({behavior: 'smooth'});
            }
        }
    }

    public onTemplateChange(form: NgForm): void {
        if (form.controls.selectedPosition) {
            form.controls.selectedPosition.reset();
        }
    }
}
