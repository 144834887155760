import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AttendanceService } from 'src/app/employee/services/attendance.service';
import { Employee } from '../../../models/Employee';

export interface EmployeeAttendanceOverview {
    dates: {
        absence: {
            from: string;
            to: string;
            total: string;
        };
        date: string;
        is_holiday: boolean;
        is_weekend: boolean;
        saldo: string;
        work: {
            from: string;
            to: string;
            total: string;
        };
    }[];
    employee: Employee;
    summary: {
        protection_state: {
            protected_ratio: number;
            protected_records: number;
            total_records: number;
        }
        whole_month: {
            expected: string;
            real: string;
            saldo: string;
            holidays_work: string;
            weekends_work: string;
            holidays_taken: string;
            holidays_not_work: string;
            worktime_summaries: [key: string, value: number];
            absence_summaries: [key: string, value: number];
            neutral_summaries: [key: string, value: number];
        }
    };
    month: number;
    year: number;
    is_uneven: boolean;
}

@Component({
    selector: 'app-employee-attendance-document-employee-detail',
    templateUrl: './employee-attendance-document-employee-detail.component.html',
    styleUrls: ['./employee-attendance-document-employee-detail.component.css']
})
export class EmployeeAttendanceDocumentEmployeeDetailComponent implements OnInit {
    public employeeID: number;
    public month: number;
    public year: number;
    public data$: Observable<EmployeeAttendanceOverview>;
    public nextYear: number;
    public nextMonth: number;
    public nextActive: boolean;
    public prevYear: number;
    public prevMonth: number;
    public from: Date;
    public to: Date;

    public constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _attendanceService: AttendanceService,
        protected _changeDetectorRef: ChangeDetectorRef,
    ) {}

    public ngOnInit(): void {
        this.employeeID = parseInt(this._route.snapshot.params.id, 10);
        this.init();
    }

    public init(): void {
        const routeMonth = this._route.snapshot.queryParamMap.get('month');
        const routeYear = this._route.snapshot.queryParamMap.get('year');

        if (routeMonth === 'current') {
            this.month = new Date().getMonth() + 1;
        } else {
            this.month = parseInt(routeMonth, 10);
        }

        if (routeYear === 'current') {
            this.year = new Date().getFullYear();
        } else {
            this.year = parseInt(routeYear, 10);
        }

        this.from = new Date(this.year, this.month - 1, 1);
        this.to = new Date(this.year, this.month, 0);

        this.nextYear = this.month === 12 ? this.year + 1 : this.year;
        this.nextMonth = this.month === 12 ? 1 : this.month + 1;
        this.nextActive = new Date(this.nextYear, this.nextMonth - 1, 1) <= new Date();
        this.prevYear = this.month === 1 ? this.year - 1 : this.year;
        this.prevMonth = this.month === 1 ? 12 : this.month - 1;
        this.data$ = this._attendanceService.getAttendanceOverviewByEmployee(this.employeeID, this.month, this.year);
    }

    public goToMonthOverview(year: number, month: number): void {
        this._router.navigate(
            ['employee/attendance/documents/detail/employee', this.employeeID],
            {queryParams: {month, year}}
        ).then(() => {
            this.init();
        }, () => {});
    }

    public downloadAsPDF(year: number, month: number, data: EmployeeAttendanceOverview): void {
        const filename = `${year}-${month} - ${data.employee.fullname}.pdf`;
        this._attendanceService.downloadEmployeeAttendancePdf(this.employeeID, filename, month, year);
    }

    public recalculateDatetimes() {
        const from = new Date(this.year, this.month - 1, 1);
        const formattedFrom = `${from.getFullYear()}-${String(from.getMonth() + 1).padStart(2, '0')}-${String(from.getDate()).padStart(2, '0')}`;
        const to = new Date(this.year, this.month, 0);
        const formattedTo = `${to.getFullYear()}-${String(to.getMonth() + 1).padStart(2, '0')}-${String(to.getDate()).padStart(2, '0')}`;
        this._attendanceService.recalculateDatetimes([this.employeeID], null, formattedFrom, formattedTo)
            .then(
                result => {
                    if (result) {
                        this.init();
                    }
                },
                () => { }
            );
    }
}
