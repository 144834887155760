import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AttendanceService } from '../../../services/attendance.service';

@Component({
    selector: 'app-protect-unprotect-datetimes',
    templateUrl: './protect-unprotect-datetimes.component.html',
    styleUrls: ['./protect-unprotect-datetimes.component.css']
})
export class ProtectUnprotectDatetimesComponent {
    @Input() public state = true;
    @Input() public from: Date;
    @Input() public to: Date;
    @Input() public employees: any;
    @Input() public employeeDatetimeId: number;

    @Output() public stateChanged = new EventEmitter<void>();

    public constructor(
        private attendanceService: AttendanceService,
    ) { }

    public protectUnprotectDatetime(protect = true): void {
        let employeeIds = null;
        if (typeof this.employees === 'number') {
            employeeIds = [this.employees];
        }
        if (Array.isArray(this.employees)) {
            employeeIds = this.employees.map(employee => employee.employee_ID);
        }

        const formattedFrom = `${this.from.getFullYear()}-${String(this.from.getMonth() + 1).padStart(2, '0')}-${String(this.from.getDate()).padStart(2, '0')}`;
        const formattedTo = `${this.to.getFullYear()}-${String(this.to.getMonth() + 1).padStart(2, '0')}-${String(this.to.getDate()).padStart(2, '0')}`;

        this.attendanceService.protectOrUnprotectDatetimes(protect, employeeIds, this.employeeDatetimeId, formattedFrom, formattedTo)
            .then(() => this.stateChanged.emit(),
                () => {}
            );
    }
}
