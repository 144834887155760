import { Component, Input } from '@angular/core';
import { FileTypeType, getFileType } from './file-type.functions';

const NOT_SUPPORTED = 'not_supported';

const SUPPORTED = [
    'document',
    'image',
    'pdf',
    'presentation',
    'spreadsheet',
];

@Component({
    selector: 'app-file-type',
    templateUrl: './file-type.component.html',
    styleUrls: ['./file-type.component.css']
})
export class FileTypeComponent {
    @Input() public oneDriveID = null;
    @Input() public signiRelated = false;
    @Input() public widthInPx = 32;
    @Input() public heightInPx = 24;
    public NOT_SUPPORTED = NOT_SUPPORTED;
    public icon: FileTypeType | typeof NOT_SUPPORTED;

    @Input()
    public set ext(ext: string) {
        const fileType = getFileType(ext);

        if (SUPPORTED.indexOf(fileType) > -1) {
            this.icon = fileType;
        } else {
            this.icon = NOT_SUPPORTED;
        }
    }
}
